<template>
  <div
    class="rounded-lg border border-light-gray bg-white px-2 py-3 md:p-4 w-full"
  >
    <div class="flex items-center gap-3 justify-between">
      <span class="font-semibold" v-if="params.title" v-html="params.title">
      </span>
      <Icon name="fire" class="w-6 h-6 inline text-orange ml-auto" />
    </div>
    <div class="mt-4">
      <template v-if="status == 'success'">
        <LeaderboardItem
          v-for="author in authors"
          :key="author.id"
          :author="author"
          :status="status"
          :class="author.id === loggedInUserID ? 'bg-lightest-gray' : null"
        />
      </template>
      <LeaderboardAnimation :items="5" v-else />
    </div>
    <div
      v-if="!authors.length && status == 'success' && params.not_found"
      class="h-52 flex items-center justify-center text-center"
      v-html="params.not_found"
    ></div>
    <p
      class="mt-4 text-center text-sm font-semibold text-mid-gray"
      v-if="params.footer"
      v-html="params.footer"
    ></p>
  </div>
</template>

<script>
import API from "@/api";
import store from "@/store";
import Util from "@/lib/Util";
import { useQuery } from "vue-query";
import { computed, watch } from "vue";
import Icon from "@/components/Icons.vue";
import LeaderboardItem from "./LeaderboardItem.vue";
import LeaderboardAnimation from "./LeaderboardAnimation.vue";

export default {
  name: "Leaderboard",
  props: {
    params: Object,
  },
  components: {
    Icon,
    LeaderboardItem,
    LeaderboardAnimation,
  },
  setup(props) {
    const params = computed(() => {
      var params = Util.removeEmptyParams(
        JSON.parse(JSON.stringify(props.params))
      );

      delete params["title"];
      delete params["footer"];
      delete params["not_found"];

      return params;
    });

    const requestAPI = () => API.requestLeaderboard(params.value);

    const loggedInUserID = computed(() => {
      if (store.state.localSettings && store.state.localSettings.id)
        return parseInt(store.state.localSettings.id);
      return 0;
    });

    const { status, data, refetch } = useQuery("leaderboardQuery", requestAPI, {
      enabled: false,
    });

    const fetchLeaderboard = () => refetch.value();

    const authors = computed(() =>
      data.value && data.value.data ? data.value.data : []
    );

    watch(
      () => loggedInUserID.value,
      () => {
        fetchLeaderboard();
      },
      { immediate: true }
    );

    return {
      authors,
      status,
      loggedInUserID,
    };
  },
};
</script>
