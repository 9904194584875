<template>
  <ul v-if="isEditable">
    <li class="inline-block mr-2">
      <router-link
        :to="getPostLink()"
        class="btn border-yka-blue bg-white"
        v-if="getPostLink()"
        >View</router-link
      >
    </li>
    <li class="inline-block mr-2" v-if="getPostType != 'poll'">
      <router-link :to="getEditLink()" class="btn border-dawn-pink bg-dawn-pink"
        >Edit</router-link
      >
    </li>
    <li class="inline-block">
      <button
        @click="deletePost"
        class="btn border-yka-blue text-white bg-yka-blue"
      >
        Delete
      </button>
    </li>
  </ul>
</template>
<script>
import API from "@/api";
import Util from "@/lib/Util";

import { ref, computed } from "vue";

export default {
  props: {
    post: Object,
  },
  setup(props, context) {
    const unreviewed =
      window.ykasettings &&
      window.ykasettings.exclude_categories &&
      window.ykasettings.exclude_categories.unreviewed
        ? window.ykasettings.exclude_categories.unreviewed
        : 0;

    const getPostLink = () => {
      if (props.post.status == "publish") return Util.getPostLink(props.post);
      return "";
    };

    const getPostType = computed(() => props.post.type);

    const getEditLink = () => Util.getEditPostLink(props.post);

    const deleteFlag = ref(false);

    const postTypes = {
      post: "posts",
      note: "note",
      poll: "poll",
    };

    /*
     * DELETE POST
     * AFTER USER CONFIRMS
     */
    const deletePost = () => {
      if (confirm("Are you sure you want to delete this?")) {
        if (postTypes[props.post.type] == "posts") {
          API.deleteFEP(props.post.id).then(() => {
            deleteFlag.value = true;
            context.emit("deletePost", props.post.id);
          });
        } else {
          API.requestPost(postTypes[props.post.type], props.post.id, {
            method: "delete",
          }).then(() => {
            deleteFlag.value = true;
            context.emit("deletePost", props.post.id);
          });
        }
      }
    };

    const isEditable = computed(
      () =>
        props.post.type === "note" ||
        props.post.categories.includes(unreviewed) ||
        props.post.status == "draft"
    );

    const isPublished = computed(() => props.post.status == "publish");

    return {
      isEditable,
      getPostLink,
      getEditLink,
      deletePost,
      deleteFlag,
      unreviewed,
      isPublished,
      getPostType,
    };
  },
};
</script>
<style scoped>
.btn {
  @apply border rounded-sm  p-1 px-4 text-xs;
}
</style>
