<template>
  <Modal v-show="show" @close="onClose()" class="streak-help-modal">
    <template v-slot:modalcontent>
      <h3 class="text-center text-2xl font-bold">Streak Reward Amounts</h3>
      <p class="my-4 text-sm text-mid-gray">
        Here's the complete list of actions / sources that earn you Streak.
      </p>
      <table class="w-full">
        <thead>
          <tr>
            <th>Action</th>
            <th>Streak</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(action, index) in streakActions" :key="index">
            <td>
              <p class="streak-action">{{ action.title }}</p>
              <p class="streak-action-detail">{{ action.detail }}</p>
            </td>
            <td>
              <div class="total-streak">
                <Icon name="fire" />
                <span>{{ action.points }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </Modal>
</template>
<script>
import { defineAsyncComponent } from "vue";
import Icon from "@/components/Icons.vue";

const Modal = defineAsyncComponent(() =>
  import(/* webpackChunkName: "Chunks1" */ "@/lib/Modal.vue")
);

export default {
  name: "StreakHelpModal",
  props: {
    show: Boolean,
    onClose: Function,
  },
  components: {
    Icon,
    Modal,
  },
  setup() {
    const streakActions = [
      {
        title: "Create Poll",
        detail: "When you create a Poll",
        points: 5,
      },
      {
        title: "Respond to a Poll",
        detail: "When you respond to a Poll",
        points: 2,
      },
      {
        title: "Create Post",
        detail: "When you create a Post",
        points: 10,
      },
      {
        title: "Delete Post",
        detail: "When you delete a Post",
        points: -10,
      },
      {
        title: "Delete Poll",
        detail: "When you delete a Poll",
        points: -5,
      },
    ];

    return { close, streakActions };
  },
};
</script>
<style>
.streak-help-modal th,
.streak-help-modal td {
  @apply text-left pb-4;
}

.streak-help-modal th {
  color: #737273;
}

.streak-help-modal .streak-action {
  @apply font-semibold;
}

.streak-help-modal .streak-action-detail {
  @apply text-sm text-mid-gray;
}

.streak-help-modal .total-streak {
  @apply flex items-center gap-2;
}

.streak-help-modal .total-streak svg {
  @apply w-4 h-4 md:w-6 md:h-6 inline text-orange font-bold;
}
</style>
