<template>
  <div
    class="w-full flex items-center justify-between gap-3 p-2 rounded-lg"
    v-bind="$attrs"
  >
    <div class="flex flex-1 items-center justify-start gap-3">
      <span
        class="writer-rank text-md font-bold text-mid-gray"
        v-html="getRankText(author)"
      ></span>
      <span class="avatar-wrap">
        <AuthorAvatar :author="author" :status="status" class="avatar-wrap" />
      </span>
      <AuthorName
        class="text-left text-sm font-semibold line-clamp-2 break-all w-full block"
        :author="author"
        :status="status"
      />
    </div>
    <div class="text-sm font-semibold flex items-center justify-center gap-1">
      <Icon name="fire" class="w-5 h-5 text-orange" />
      <span>{{ author?.leaderboard_points }}</span>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/Icons.vue";
import AuthorAvatar from "@/templates/author/Avatar.vue";
import AuthorName from "@/templates/author/Name.vue";

export default {
  name: "LeaderboardItem",
  props: {
    author: Object,
    status: String,
  },
  components: {
    Icon,
    AuthorName,
    AuthorAvatar,
  },
  setup() {
    const getRankText = (author) => author?.leaderboard_rank || "&mdash;";

    return {
      getRankText,
    };
  },
};
</script>
<style scoped>
.writer-rank {
  min-width: 18px;
}

.avatar-wrap {
  max-width: 2.25rem;
  max-height: 2.25rem;
  @apply w-full h-9;
}
</style>
