<template>
  <div class="text-sm md:text-base md:my-2" v-if="status == 'success'">
    <Icon
      name="fire"
      class="w-4 h-4 md:w-6 md:h-6 inline text-orange font-bold"
    />
    <button @click="handleClick">{{ author.total_points }}</button>
  </div>
  <div class="h-1.5 bg-light-gray rounded animate-pulse" v-else></div>
  <StreakHelpModal :show="show" :onClose="closeStreakModal" />
</template>
<script>
import Icon from "@/components/Icons.vue";
import StreakHelpModal from "@/components/StreakHelpModal.vue";
import { ref } from "vue";

export default {
  props: {
    author: Object,
    status: String,
  },
  components: {
    Icon,
    StreakHelpModal,
  },
  setup() {
    const show = ref(false);

    const handleClick = () => {
      show.value = true;
    };

    const closeStreakModal = () => {
      show.value = false;
    };

    return {
      show,
      handleClick,
      closeStreakModal,
    };
  },
};
</script>
