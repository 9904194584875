<template>
  <div
    v-for="index in items"
    :key="index"
    class="flex w-full flex-row items-center justify-between rounded-lg p-2"
  >
    <div class="flex flex-row items-center justify-start gap-3">
      <div class="h-4 w-2 mr-2 rounded bg-light-gray animate-pulse"></div>
      <div class="h-9 w-9 rounded-full bg-light-gray animate-pulse"></div>
      <div class="h-4 w-24 rounded bg-light-gray animate-pulse"></div>
    </div>
    <div class="flex flex-row items-center justify-center gap-1">
      <div class="h-4 w-4 rounded bg-light-gray animate-pulse"></div>
      <div class="h-4 w-8 rounded bg-light-gray animate-pulse"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LeaderboardAnimation",
  props: {
    items: {
      type: Number,
      default: () => 1,
    },
  },
};
</script>
